/* Global SCSS */

/* Variables */

$white: #fff;
$black: #000;
$primary: #f6911e;
$secondary: #01b9af;
$darker: #676767;
$danger: #fa3d3d;
$light: #d9d9d9;

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: $white !important;
}
#root {
  width: 100%;
  height: 100%;
}

main {
  position: relative;
  min-height: calc(100vh - 356px);
  margin-top: 97px;
}

/* Buttons */
.rounded-btn {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  min-width: auto !important;
  padding:0 !important;
}
.btn-white {
  border-color: $white !important;
  color: $white !important;

  &:hover {
    background-color: $white !important;
    color: $primary !important;
  }
}
.MuiButton-outlined {
  border-color: inherit !important;
  border-width: 2px !important;
}
.Mui-disabled.MuiButton-containedPrimary {
  background-color: $primary !important;
  color: $white !important;
  opacity: 0.6;
}
.MuiButton-containedSecondary:hover {
  color: $secondary !important;
}
.MuiButton-outlinedSecondary:hover {
  color: $white !important;
  background-color: $secondary !important;
  border-color: $secondary !important;
}

/* Links */
a {
  text-decoration: none;
  &.MuiLink-root:hover {
    opacity: 0.7;
  }
  &:not(
      .tab-list a,
      .MuiButton-root,
      .menu-list a,
      .site-footer a,
      .maillink,
      .social a
    ) {
    font-style: italic;
    color: $primary;
  }
}
.MuiLink-root.MuiTypography-body2 {
  font-weight: 600;
}

/* Layout */
.container-wrap{
  max-width: 800px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow:
    rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.container-wrapper {
  padding-right: 67px;
  padding-left: 67px;
}

.MuiContainer-maxWidthXl {
  max-width: 93% !important;
}

/* Text */
.MuiTypography-subtitle1 {
  color: $darker;
}
.text-sm {
  font-size: 14px !important;
  line-height: 23px;
}
.MuiTypography-body1 {
  color: $black;
}

.MuiTypography-body2 {
  color: $darker;
}
.text-small {
  font-size: 0.875rem !important;
  color: $darker !important;
  line-height: 1.625rem !important;
  font-weight: 300 !important;
}

.text-underline {
  text-decoration: underline !important;
}

/* Forms */
.success-text {
  color: #229a16;
}
.MuiCheckbox-root + .MuiFormControlLabel-label {
  margin-left: 10px;
  margin-right: 0;
}
.MuiSelect-select.MuiSelect-outlined {
  white-space: inherit !important;
  padding-right: 54px !important;
}
.MuiCheckbox-root {
  .MuiSvgIcon-root {
    font-size: 16px;
  }
}
.MuiCheckbox-root {
  padding: 0 !important;
  color: $darker !important;
}

.MuiCheckbox-root.Mui-checked {
  color: $primary !important;
}
label {
  color: $darker;
}
.MuiFormControlLabel-label,
.label-style {
  color: $darker !important;
  font-size: 14px !important;
  line-height: 1.4rem !important;
  text-transform: capitalize;
}

.MuiRadio-root {
  color: $darker !important;
  padding: 0 !important;
  background-color: $white !important;
  margin-right: 11px !important;

  .MuiSvgIcon-root {
    font-size: 16px;
  }
}
input::-webkit-datetime-edit-day-field,
input::-webkit-datetime-edit-month-field,
input::-webkit-datetime-edit-year-field {
  text-transform: uppercase;
}
.Mui-error {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.MuiFormLabel-root[data-shrink='false'] {
  left: 17px;
  top: 2px;
}

input[type='file'] {
  opacity: 0;
  cursor: pointer;
  border-radius: 20px;
  padding: 0 !important;
  width: 100%;
  height: 172px;
}

input[type='file'] + fieldset {
  border: 2px dashed #131c23;
}

.MuiInputBase-multiline {
  padding: 0 !important;
}
.MuiFormLabel-root.error,
.MuiFormHelperText-root {
  color: $danger;
  font-size: 16px !important;
  font-weight: 500 !important;
}

textarea {
  height: 120px !important;
}

.MuiFormLabel-root,
.MuiMenu-list > li {
  font-size: 0.9375rem !important;
  line-height: 1.375rem !important;
}
.autofill-input legend {
  max-width: 100%;
}
.autofill-input .MuiFormLabel-root[data-shrink='false'] {
  left: auto;
  top: auto;
}

.autofill-input label {
  max-width: calc(133% - 32px);
  transform: translate(14px, -9px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.MuiInputBase-input,
textarea {
  padding: 1.1875rem 2.0625rem !important;
}

.MuiInputBase-input {
  font-size: 15px !important;
  line-height: 22px !important;
  background-color: rgb(232, 240, 254);
}
.MuiSelect-select {
  position: relative;
}

.MuiSelect-icon {
  display: none !important;
}

.MuiSelect-select::after {
  content: '';
  background-image: url('../public/assets/icons/arrow_down.svg');
  position: absolute;
  right: 32px;
  width: 20px;
  height: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 50%;
  transform: translateY(-50%);
}
input {
  &[type='number'] {
    &::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }
}
.rounded-upload.upload-photo {
  max-width: 285px;
  margin-left: auto;
  margin-right: auto;
}

.rounded-upload.upload-photo input[type='file'] + fieldset {
  height: 285px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
}

.rounded-upload.upload-photo input[type='file'],
.rounded-upload.upload-photo input[type='file'] + fieldset {
  border-radius: 50%;
  height: 285px;
}

/* Dropdown Popover */
.MuiAutocomplete-popper{
  border-radius: 6px !important;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important;
}

/* Images */
.img-absolute {
  position: absolute;
  inset: 0;
}
img {
  display: block;
  max-width: 100%;
}
.img-style {
  width: 100%;
  height: 100%;
  &__cover {
    object-fit: cover;
  }
  &__contain {
    object-fit: contain;
  }
}

/* List */
.MuiList-root[role='listbox'] {
  max-height: 70vh;
}
.MuiList-root .MuiMenuItem-root {
  white-space: inherit !important;
}
ul {
  margin: 0;
  padding: 0;
}
/* Accordian */
.MuiAccordion-root {
  border-bottom: 1px solid $light;
  text-align: left;
}

.MuiAccordionSummary-contentGutters {
  margin: 17px 0 !important;
}

.MuiAccordionSummary-content:not(.Mui-expanded) .sub-cont,
.category-list .MuiTabs-indicator,
.MuiAccordion-root:before,
.Mui-expanded .add-cont {
  display: none;
}

.Mui-expanded .sub-cont {
  display: block;
}

/* Tooltip */
.MuiTooltip-arrow {
  &:before {
    font-size: 0.68rem;
  }
}

/* cards */
.card-block{

    border-radius: 2.5rem;
    box-shadow: 0px 0px 20px 0px rgba(175, 175, 175, 0.2);
    padding: 3rem 1.5rem 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* modal */
.MuiDialog-paper.MuiDialog-paperWidthSm {
  max-width: 1114px;
  width: 100%;
}

/* Custom Classes */
.vertical-center {
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }
}
.label-style {
  font-size: 1rem !important;
  line-height: 1.2 !important;
  margin-bottom: 18px;
  display: block;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sec-spacing {
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Header */
.menu-list + div > button svg {
  color: $primary !important;
  font-size: 2em;
}
/* banner */
.banner-wrap {
  object-fit: cover;
  height: 100%;
  display: block;
  position: absolute;
  width:100%;
  inset:0;
}
.banner{
  position: relative;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgb(0 0 0 / 28%);
    z-index: 1;
  }
}
.banner-content{
  position: relative;
    max-width: 1080px;
    width: 100%;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 2;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
}

/* Gallery Share List */

.share-list {
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  &:before {
    content: '';
    width: 11px;
    height: 11px;
    position: absolute;
    background-color: $white;
    top: 5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  button{
      min-width: auto!important;
    padding: 0 !important;
    margin-bottom: 10px !important;
  }
  a {
    svg {
      fill: $primary !important;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
.share-btn,
.share-list {
  svg {
    font-size: 1.5rem;
    height: 22px;
    width: 22px;
    margin-bottom: 2px;
    margin-top: 2px;
  }
}
/* Testimonial Section*/
.testimonial-sec__content {
  .MuiIconButton-root[aria-label='Previous'],
  .MuiIconButton-root[aria-label='Next'] {
    opacity: 0.4;
  }
}
.curve-bg:after {
  background-image: url('../public/assets/images/blue-curve.png');
  content: '';
  position: absolute;
  height: 16rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: $white;
  width: 100%;
  bottom: 0;
}
.curve-sec {
  margin-top: 110px;
  margin-bottom: 215px;
  &::before,
  &::after {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    width: 100%;
    height: 200px;
    content: '';
  }
  &::before {
    top: -200px;
    left: 0;
  }
  &::after {
    bottom: -200px;
    left: 0;
  }
}
.testimonial-bg > div > div:last-child > div {
  padding-bottom: 0;
  margin-top: 2rem;
}

.testimonial-bg > div > div:last-child > div h3 + p {
  margin-bottom: 0;
}
.quotes {
  position: relative;
}

.quotes:before,
.quotes:after {
  display: inline-block;
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
}
.curve-bg.curve-bg__bottom::after {
  height: 27rem;
  bottom: -27rem;
}

/* About Section */
.graphic-img {
  left: 50%;
  transform: translateX(-50%);
}

/* Form section */
.upload-file {
  width: 100%;
  border: 1px dashed $secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  border-radius: 6px;
  gap: 2px;
  box-shadow: 0px 0px 6.2px 0px #b7b7b740;
  cursor: pointer;
}
.label-style {
  font-size: 1rem !important;
  line-height: 1.2 !important;
  margin-bottom: 18px;
  display: block;
}
.upload-field {
  max-width: 374px;
  width: 100%;
  position: relative;
}

.upload-field > div {
  width: 100%;
}

/* Pets Section*/
.details-title {
  max-width: 325px;
  margin: 0 auto;
  text-align: center;
}
.pets-gender {
  width: 100%;

  > div {
    width: 100%;

    label {
      margin-left: 0;
    }

    label:nth-child(1) {
      width: calc(40% - 16px);
    }

    label:nth-child(2) {
      width: calc(60% - 16px);
    }
  }
}
.pets-filter {
  .MuiAutocomplete-input {
    padding: 9px !important;
  }
}

.search-field {
  position: relative;
  max-width: 630px;
  margin: 0 auto 3rem;

  .MuiInputBase-input {
    padding-left: 3.3rem !important;
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2px;
  }
}
/* 404 Page */
.error__wrapper {
  text-align: center;
  h1 {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5rem;
    color: $white;
    margin: 0;
    color: $white;
  }
  p {
    color: $white;
    font-size: 1.5rem;
    margin-top: 0;
  }
  img {
    max-width: 130px;
  }
  a {
    background: $white;
    display: inline-block;
    text-decoration: none;
    color: $black;
    padding: 0.5rem;
    border-radius: 20px;
    font-size: 0.875rem;
    margin-top: 3rem;
    transition: 0.3s all ease-in;
    border: 1px solid $white;
    padding-left: 20px;
    padding-right: 20px;
    &:hover {
      background: $black;
      color: $white;
    }
  }
}
.page-not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $black;
  margin-top: -97px;
}
/* Text Edit Component Style*/
.petlist-style {
  .MuiListItem-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .MuiListItemText-root {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.body2-style {
  .MuiTypography-body2 {
    font-size: 13px;
  }
}

.h2-style,
.text-editor {
  h2 {
    margin: 0;
    font-weight: 600;
    line-height: 1.5;
    font-size: 1.375rem;
    font-family: Poppins, sans-serif;
    margin-bottom: 8px;
  }
}

.text-white {
  * {
    color: $white !important;
  }
}

.banner-content {
  h1 {
    font-weight: 600;
    font-size: 1.875rem;
    margin-bottom: 0;
  }

  h3 {
    font-size: 18px;
    margin-top: 13px;
  }

  .MuiTypography-body1 {
    margin-top: 4rem;
    margin-bottom: 3.5rem;
  }

  .MuiButton-contained {
    margin-bottom: 10px !important;
  }
}

.body1-style {
  .MuiTypography-body1 {
    margin: 0;
    line-height: 1.6;
    font-size: 0.875rem;
    font-family: Poppins, sans-serif;
    font-weight: 400;
  }
}

/* Responsive Style */

/* Based on min width */
@media (min-width: 600px) {
  .text-small {
    font-size: 0.875rem;
  }
  /* Text Edit Component Style*/
  .MuiFormControlLabel-label,
  .label-style {
    font-size: 16px !important;
  }

  .body2-style {
    .MuiTypography-body2 {
      font-size: 14px;
    }
  }

  .h2-style h2,
  .text-editor h2 {
    font-size: 1.5625rem;
  }

  .body1-style .MuiTypography-body1 {
    font-size: 1rem;
  }

  .banner-content {
    h1 {
      font-size: 2.125rem;
    }

    h3 {
      font-size: 20px;
    }
  }
}

@media (min-width: 900px) {
  .MuiFormControlLabel-label,
  .label-style {
    font-size: 17px !important;
  }

  .text-small {
    font-size: 0.9375rem;
  }
  /* Text Edit Component Style*/
  .body2-style {
    .MuiTypography-body2 {
      font-size: 15px;
    }
  }

  .h2-style h2,
  .text-editor h2 {
    font-size: 1.75rem;
  }

  .banner-content {
    h1 {
      font-size: 2.25rem;
    }

    h3 {
      font-size: 23px;
    }
  }
}

@media (min-width: 1200px) {
  .MuiFormControlLabel-label,
  .label-style {
    font-size: 18px !important;
  }

  .text-small {
    font-size: 1rem;
  }

  .curve-bg:after {
    height: 24rem;
  }

  .save-btn {
    margin-bottom: 3.5rem;
  }

  .graphic-img {
    max-width: 85%;
  }
  /* Text Edit Component Style*/
  .body2-style {
    .MuiTypography-body2 {
      font-size: 16px;
    }
  }

  .h2-style h2,
  .text-editor h2 {
    font-size: 2rem;
  }

  .banner-content {
    h1 {
      font-size: 2.375rem;
    }

    h3 {
      font-size: 26px;
    }
  }

  .body1-style .MuiTypography-body1 {
    font-size: 1.25rem;
  }
}
@media (min-width: 1920px) {
  .MuiContainer-maxWidthXl {
    max-width: 1536px !important;
  }

  /* Text Edit Component Style*/

  .text-editor {
    position: relative;

    &.editor-container {
      &:focus {
        outline: none;
      }
    }

    &.editing {
      border: 4px solid $primary;
      border-radius: 5px;
    }

    &.edit-icon {
      position: absolute;
      background: $primary;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 60px;
      z-index: 9;
      color: $white;
      border-radius: 50%;
      padding: 10px;
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    &.no-editing {
      &:focus {
        outline: none;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }
}
/* Based on max width */
@media (max-width: 1200px) {
  main {
    margin-top: 85px !important;
  }
}

@media (max-width: 992px) {
  .introinfo-content {
    h2 {
      display: none;
    }
  }
}

@media (max-width: 899px) {
  .vertical-center label {
    margin-top: 8px;
    display: block;
    margin-left: 0 !important;
  }

  .curve-sec::before,
  .curve-sec::after {
    height: 150px !important;
  }

  .curve-sec::before {
    top: -150px !important;
  }

  .curve-sec::after {
    bottom: -150px !important;
  }

  main {
    margin-top: 99px !important;
  }

  .label-wrap {
    label {
      margin-left: 0;
    }
  }
}

@media (max-width: 599px) {
  .tab-content > div {
    padding: 0 !important;
  }
  .MuiContainer-maxWidthXl {
    max-width: 100% !important;
  }

  .container-wrapper {
    padding-right: 20px;
    padding-left: 20px;
  }

  .MuiButton-root {
    padding: 8px 24px !important;
  }

  .curve-sec::before,
  .curve-sec::after {
    height: 100px;
  }

  .curve-sec::before {
    top: -100px;
  }

  .curve-sec::after {
    bottom: -100px;
  }

  .curve-bg:after {
    height: 7rem;
  }

  .curve-bg__bottom::after {
    bottom: -27rem;
  }
}
